@keyframes sway {
  0%,
  100% {
    transform: translate(-50%, -5px) rotate(2deg);
  }
  50% {
    transform: translate(-50%, 5px) rotate(-2deg);
  }
}

.sway {
  /* Adjust duration (3s) and distance (±5px, ±2deg) to taste */
  animation: sway 14s linear infinite;
}
